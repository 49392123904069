import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as jose from "jose";
import Error from "./error";
import "../assets/style.css";

const trackCompleteHandler = (target) => {
    return (event) => {
        if (event.data.event === "track.user_clicked_finish") {
            setTimeout(() => {
                window.location.replace(target);
            }, 0);
        }
    };
};

const ThoughtIndustriesEmbed = ({ location }) => {
    const [track, setTrack] = useState(null);
    const [title, setTitle] = useState("Instruqt Hands-on Lab");
    const [error, setError] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        let trackURL;
        try {
            trackURL = new URL(searchParams.get("track"));
        } catch (_) {
            setError("Invalid track URL: " + searchParams.get("track"));
            return;
        }
        // Transfer the query parameters from the page URL to the track URL,
        // so users don't have to urlencode the parameters in the track URL.
        for (let [key, value] of searchParams.entries()) {
            if (!["track", "jwt"].includes(key)) {
                trackURL.searchParams.append(key, value);
            }
        }
        trackURL.searchParams.append("finish_btn_text", "Finish");
        setTrack(trackURL);

        let decodedJwt;
        try {
            decodedJwt = jose.decodeJwt(searchParams.get("jwt"));
        } catch (_) {
            setError("Invalid JWT: " + searchParams.get("jwt"));
            return;
        }
        if (!decodedJwt?.returnTo) {
            setError("Missing returnTo in JWT");
            return;
        }
        if (decodedJwt?.courseTitle) {
            setTitle(decodedJwt.courseTitle + " - Instruqt Hands-on Lab");
        }
        if (decodedJwt?.firstName) {
            trackURL.searchParams.append("rtp_USER_FIRSTNAME", decodedJwt.firstName)
        }
        if (decodedJwt?.email) {
            trackURL.searchParams.append("rtp_USER_EMAIL", decodedJwt.email)
        }

        const target = decodedJwt.returnTo;
        window.addEventListener("message", trackCompleteHandler(target));
        return () =>
            window.removeEventListener("message", trackCompleteHandler(target));
    }, [location.search]);

    if (error) {
        return <Error message={error} />;
    }

    return (
        <Fragment>
            <Helmet title={title} />
            <iframe
                title={title}
                src={track}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
                allowfullscreen
            ></iframe>
        </Fragment>
    );
};

export default ThoughtIndustriesEmbed;
